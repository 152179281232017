
import { useLocalization } from "@hooks/localization";
import { NoSsr, Typography } from "@material-ui/core";
import { StaticImage } from "gatsby-plugin-image";
import styles from "./FooterWithPaymentIcons.module.scss";

export default function FooterWithPaymentIcons(props: {
	hideDankortLogo?: boolean,
	hideVisaLogo?: boolean,
	hideMastercardLogo?: boolean,
	hideMobilePayLogo?: boolean,
	style?: React.CSSProperties
}) {
	const t = useLocalization();

	return <footer className={styles.footer} style={{ ...(props.style || {}) }}>
		<section>
			{!props.hideDankortLogo &&
				<StaticImage alt="Dankort Logo" src={"../../pages/images/dankort-logo.png"} width={60} height={36}  loading="lazy" />
			}
			{!props.hideVisaLogo &&
				<StaticImage alt="Visa Logo" src={"../../pages/images/visa-logo.svg"} width={65} height={21} loading="lazy" />
			}
			{!props.hideMastercardLogo &&
				<StaticImage alt="Mastercard Logo" src={"../../pages/images/mastercard-logo.svg"} width={58} height={41} loading="lazy" />
			}
			{!props.hideMobilePayLogo &&
				<StaticImage alt="Mobilepay Logo" src={"../../pages/images/mobilepay-logo.svg"} width={60} height={49} loading="lazy" />
			}
		</section>
		<Typography>
			Reshopper ApS <br />
			Inge Lehmanns Gade 10 <br />
			8000 Aarhus <span style={{ fontSize: "0.5em" }}>&bull;</span> Danmark <br />
			CVR. 34711763 <br />
			<NoSsr>
				<a href="/dk/kontakt">{t("Plus_Signup_Footer_Contact")}</a>
			</NoSsr>
		</Typography>
	</footer>

}