import { webClient } from "@api/web/WebClient";
import CollapseBox from "@components/web/CollapseBox";
import { withWebLayout } from "@components/web/Decorators";
import FooterWithPaymentIcons from "@components/web/FooterWithPaymentIcons";
import { FrontPageLayout } from "@components/web/layouts/FrontPageLayout";
import { useLocalization } from "@hooks/localization";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { toDanishPriceFormat } from "@utils/formatting";
import i18n from "@utils/i18n";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import CreditCard from "../images/layout/buyerProtection/credit-card.svg";
import KrussDull from "../images/layout/buyerProtection/kruss-dull.svg";
import LifeBuoy from "../images/layout/buyerProtection/lifebuoy.svg";
import Lock from "../images/layout/buyerProtection/lock.svg";
import ShieldCheck from "../images/layout/buyerProtection/shield-check.svg";
import ShieldHeartLogo from "../images/layout/buyerProtection/shield-heart.svg";
import TruckDelivery from "../images/layout/buyerProtection/truck-delivery.svg";
import styles from "./index.module.scss";

const priceForMembers = 3; 
const percentageForMembers = 3; 
const priceForNonMembers = 4; 
const percentageForNonMembers = 4; 

export default withWebLayout(function BuyerProtectionPage() {
	const t = useLocalization();

	useEffect(() => {

		const getUserLanguage = async () => {
			try {
				const languageResponse = await webClient().webUsersLanguageGet();

				const language = languageResponse.language;
				if (i18n.language !== language) {
					i18n.changeLanguage(language);
				}

			} catch (error) {
				console.error(error);
			}
		};

		getUserLanguage();
	}, []);

	return <BuyerProtectionPageTemplate>
			<Box>
				<Box className={styles.pxSection}>
					<Typography className={styles.textContainer}>
						{t("BuyerProtection_Text", {
							priceForMembers: toDanishPriceFormat(priceForMembers),
							percentageForMembers: percentageForMembers.toString(),
							priceForNonMembers: toDanishPriceFormat(priceForNonMembers),
							percentageForNonMembers: percentageForNonMembers.toString()
						})}
					</Typography>
				</Box>

				<Box className={styles.benefitsContainer}>
					<Box className={styles.benefitsInnerContainer}>
						<BuyerProtectionBenefit
							icon={ShieldCheck}
							title={t("BuyerProtection_Benefit_One_Titel")}
							text={t("BuyerProtection_Benefit_One_Text")}
							darkBackgroundColor
							isFirst
						/>
						<BuyerProtectionBenefit
							icon={Lock}
							title={t("BuyerProtection_Benefit_Two_Titel")}
							text={t("BuyerProtection_Benefit_Two_Text")}
						/>
						<BuyerProtectionBenefit
							icon={TruckDelivery}
							title={t("BuyerProtection_Benefit_Three_Titel")}
							text={t("BuyerProtection_Benefit_Three_Text")}
							darkBackgroundColor
						/>
						<BuyerProtectionBenefit
							icon={CreditCard}
							title={t("BuyerProtection_Benefit_Four_Titel")}
							text={t("BuyerProtection_Benefit_Four_Text")}
						/>
						<BuyerProtectionBenefit
							icon={LifeBuoy}
							title={t("BuyerProtection_Benefit_Five_Titel")}
							text={t("BuyerProtection_Benefit_Five_Text")}
							darkBackgroundColor
						/>
						<BuyerProtectionBenefit
							icon={ShieldHeartLogo}
							title={t("BuyerProtection_Benefit_Six_Titel")}
							text={t("BuyerProtection_Benefit_Six_Text")}
							isLast
						/>
					</Box>
				</Box>
			</Box>

		<Box className={`${styles.faqContainer} ${styles.pxSection}`}>
			<Box className={styles.faqTextContainer}>
				<Typography variant="h2" className={styles.fontAvenir}>
					{t("BuyerProtection_Faq_Title")}
				</Typography>
			</Box>
			<BuyerProtectionFAQ />
		</Box>
		<FooterWithPaymentIcons style={{ backgroundColor: "#fff" }} />
	</BuyerProtectionPageTemplate>
});


function BuyerProtectionPageTemplate(props: {
	children: React.ReactNode
}): JSX.Element {
	const t = useLocalization();
	return <>
		<FrontPageLayout removeBlueBodyBackground>
			<Helmet
				title={"Reshopper køberbeskyttelse"}>
				<meta
					name="description"
					content={"Med køberbeskyttelse kan vores brugere handle med tillid og ro i sindet, velvidende om, at deres køb er beskyttet"}
				/>
				<meta name="robots" content="index, follow" />
			</Helmet>
			<Container className={styles.buyerProtectionPage}>
				<Box className={`${styles.header} ${styles.pxSection}`}>
					<Box className={styles.logoContainer}>
						<img alt="Reshopper køberbeskyttelse" src={ShieldHeartLogo} width="125" height="125" />
					</Box>
					<Typography variant="h1" style={{ textAlign: "center" }}>
						{t("BuyerProtection_Title")}
					</Typography>
				</Box>
				{props.children}
			</Container>
		</FrontPageLayout>
	</>
};

function BuyerProtectionBenefit(props: {
	icon: string,
	title: string,
	text: string,
	darkBackgroundColor?: boolean,
	isFirst?: boolean,
	isLast?: boolean
}): JSX.Element {

	return <Box style={{
		backgroundColor: props.isLast ? "#63AFD3" : props.darkBackgroundColor ? "#EEEDE8" : "transparent",
		display: "flex",
		padding: props.isLast ? "30px 30px 40px 30px" : "30px",
		borderRadius: props.isLast ? "0 0 8px 8px" : props.isFirst ? "8px 8px 0 0" : "0",
	}}>
		<Box className={styles.benefitIconContainer}>
			<Box className={styles.benefitIconInnerContainer}>
				<Box className={styles.benefitIconWrapper}>
					<Box className={styles.benefitIcon}>
						<img alt="Reshopper køberbeskyttelse" src={props.icon} width="50" height="50" />
					</Box>
					<Box className={styles.benefitIconBackground}>
						<img alt="Reshopper køberbeskyttelse" src={KrussDull} width="80" height="71" />
					</Box>
				</Box>
				{!props.isLast && <Box className={styles.benefitLine} />}
			</Box>
		</Box>
		<Box style={{
			paddingLeft: "20px",
			color: props.isLast ? "white" : "#472C1C"
		}}>
			<Typography variant="h3" className={styles.benefitTextTitle}>{props.title}</Typography>
			<Typography>{props.text}</Typography>
		</Box>
	</Box>
}

function BuyerProtectionFAQ(): JSX.Element {
	const t = useLocalization();

	return <Box>
		<CollapseBox
			title={t("BuyerProtection_Faq_One_Title")}
			text={t("BuyerProtection_Faq_One_Text", {
				priceForMembers: toDanishPriceFormat(priceForMembers),
				percentageForMembers: percentageForMembers.toString(),
				priceForNonMembers: toDanishPriceFormat(priceForNonMembers),
				percentageForNonMembers: percentageForNonMembers.toString()
			})}
		/> 
		<CollapseBox
			title={t("BuyerProtection_Faq_Two_Title")}
			text={t("BuyerProtection_Faq_Two_Text")}
		/>
		<CollapseBox
			title={t("BuyerProtection_Faq_Three_Title")}
			text={t("BuyerProtection_Faq_Three_Text")}
		/>
		<CollapseBox
			title={t("BuyerProtection_Faq_Four_Title")}
			text={t("BuyerProtection_Faq_Four_Text")}
		/>
		<CollapseBox
			title={t("BuyerProtection_Faq_Five_Title")}
			text={t("BuyerProtection_Faq_Five_Text")}
		/>
		<CollapseBox
			title={t("BuyerProtection_Faq_Six_Title")}
			text={t("BuyerProtection_Faq_Six_Text")}
		/>
		<CollapseBox
			title={t("BuyerProtection_Faq_Seven_Title")}
			text={t("BuyerProtection_Faq_Seven_Text")}
		/>
		<CollapseBox
			title={t("BuyerProtection_Faq_Eight_Title")}
			text={t("BuyerProtection_Faq_Eight_Text")}
		/>
		<CollapseBox
			title={t("BuyerProtection_Faq_Nine_Title")}
			text={t("BuyerProtection_Faq_Nine_Text")}
		/>
	</Box>
}